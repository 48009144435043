body {
    background-color: whitesmoke;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.store-section {
    animation: slideFromTop .30s linear;
    transition: .30s ease-in-out;
}

@keyframes slideFromTop {
    0% {
        opacity: 0;
    }
    75% {
        opacity: 1;
    }
}


.bg-primary {
    background-color: #E35C39
}

.bg-primary-light {
    background-color: #F8E6E2
}

.text_light_brown {
    color: #A16859
}

.border_peach_l {
    border-left: #E0C5BF dashed 1px;
}

.border_peach_r {
    border-right: #E0C5BF dashed 1px;
}

.border_peach_t {
    border-top: #E0C5BF dashed 1px;
}

.border_peach_b {
    border-bottom: #E0C5BF dashed 1px;
}

.border_peach_dark {
    border: #dcceca solid 1px;
    border-radius: 16px;
}

.text-primary {
    color: #E35C39
}

.bg-secondary {
    background-color: #F4B54B
}

.text-secondary {
    color: #F4B54B
}

.shadow-top-right {
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
}

.truncate-custom {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.border-gray {
    border: #dcdcdc 1px solid;
}

