.ql-editor {
    font-family: "Inter", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-size: 16px !important;
    line-height: 1.5;
    padding-left: 26px !important;

    h1, h2, h3, h4, h5, h6 {
        margin-top: 0.4em;
        margin-bottom: 0.3em;
    }

    h3 {
        font-size: 1.2em !important;
        font-weight: bold !important;
    }
}

.popup {
    position: absolute;
    background-color: #ffffff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 10px;
    z-index: 10000000 !important;
}

.menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.menu li {
    padding: 5px 10px;
    cursor: pointer;
}

.menu li:hover {
    background-color: #f0f0f0;
}

.menu li:first-child {
    border-top: 1px solid #ccc;
}

.menu li:last-child {
    border-bottom: 1px solid #ccc;
}

.ql-formats {
    border: none !important;
    padding: 0 !important;
    padding-left: 10px !important;
}

.section-input-header {
    outline: none !important;
    border: none !important;
}

input.section-input-header:focus {
    outline: none !important;
    border: none !important;
}




// .product-center {
//     align-items: center;
//     padding: 10px;
// }

// .product-image {
//     object-fit: cover;
//     margin-right: 10px;
// }

// .product-title-center {
//     font-size: 16px;
//     font-weight: bold;
//     margin-bottom: 5px;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
// }

// .product-price-center {
//     font-size: 14px;
//     margin-bottom: 5px;
//     padding-bottom: 5px;
// }

// .product-button-center {
//     margin-top: 10px;
//     padding: 8px 16px;
//     background-color: #000000;;
//     color: white;
//     border: none;
//     border-radius: 4px;
//     cursor: pointer;
//     width: 100%;
//     text-align: center;
// }
//-----------------------
// .tooltip {
//     position: relative;
//     /* display: inline-table; */
//     /* border-bottom: 1px dotted black; */
//     /* Add cursor pointer to indicate hoverability */
//     cursor: pointer;
//     white-space: nowrap;
//     color: green !important;
//     font-weight: 700 !important;
//     border: green 1px solid;
//     padding: 2px !important;
//     border-radius: 6px !important;
//     //z-index: 1000 !important;
//     text-decoration: none;
//     /*text-decoration: underline;*/
// }

// .tooltip .tooltiptext {
//     visibility: hidden;
//     width: 200px;
//     background-color: white;
//     color: #000000;
//     text-align: center;
//     border-radius: 6px;
//     padding: 10px;
//     position: absolute;
//     z-index: 1;
//     bottom: 125%;
//     left: 50%;
//     margin-left: -100px;
//     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
//     opacity: 0;
//     transition: opacity 0.3s;
// }

// .tooltip:hover .tooltiptext {
//     visibility: visible;
//     opacity: 1;
// }

// .tooltip .tooltiptext img {
//     display: block;
//     margin: auto;
//     max-width: 100%;
//     max-height: 100%;
//     margin-bottom: 10px;
// }

// .tooltip .tooltiptext p {
//     margin: 0;
//     font-size: 14px;
//     line-height: 1.4;
//     white-space: normal;
// }

// .tooltip .tooltiptext::before {
//     content: "";
//     position: absolute;
//     bottom: -5px;
//     left: 50%;
//     transform: translateX(-50%) rotate(45deg);
//     width: 10px;
//     height: 10px;
//     background-color: #ffffff;
//     box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
//     border-radius: 2px;
// }


// .product-center {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     padding: 10px;
// }

// .product-image {
//     width: 200px;
//     height: 200px;
//     object-fit: cover;
//     margin-bottom: 10px;
// }

// .product-title-center {
//     font-size: 16px;
//     font-weight: bold;
//     text-align: center;
//     margin-bottom: 5px;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
// }

// .product-price-center {
//     font-size: 14px;
//     text-align: center;
//     margin-bottom: 10px;
// }

// .product-button-center {
//     margin-top: 10px;
//     padding: 8px 16px;
//     background-color: #000000;
//     color: white;
//     border: none;
//     border-radius: 4px;
//     cursor: pointer;
//     width: 100%;
//     text-align: center;
// }

// .tooltip-center {
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

//--------------------
.tooltip {
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    color: green !important;
    font-weight: 700 !important;
    // border: green 1px solid;
    padding: 2px !important;
    border-radius: 6px !important;
    text-decoration: none;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: auto; /* Allow dynamic width */
    max-width: 200px; /* Set a max width for better layout */
    background-color: white;
    color: #000000;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 0.3s;
    word-wrap: break-word; /* Wrap text */
}

.tooltip .tooltiptext img {
    display: block;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 10px;
}

.tooltip .tooltiptext p {
    margin: 0;
    font-size: 14px;
    line-height: 1.4;
    white-space: normal; /* Allow content to wrap */
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.tooltip .tooltiptext::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #ffffff;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    transform: rotate(45deg);
}

.tooltip[data-position="top"] .tooltiptext {
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
}

.tooltip[data-position="top"] .tooltiptext::before {
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
}

.tooltip[data-position="bottom"] .tooltiptext {
    top: 125%;
    left: 50%;
    transform: translateX(-50%);
}

.tooltip[data-position="bottom"] .tooltiptext::before {
    top: -5px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
}

.tooltip[data-position="left"] .tooltiptext {
    right: 125%;
    top: 50%;
    transform: translateY(-50%);
}

.tooltip[data-position="left"] .tooltiptext::before {
    right: -5px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
}

.tooltip[data-position="right"] .tooltiptext {
    left: 125%;
    top: 50%;
    transform: translateY(-50%);
}

.tooltip[data-position="right"] .tooltiptext::before {
    left: -5px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
}


.product-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.product-image {
    width: 200px;
    height: 200px;
    object-fit: cover;
    margin-bottom: 10px;
}

.product-title-center {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal !important; 
    word-wrap: break-word !important; 
}

.product-price-center {
    font-size: 14px;
    text-align: center;
    margin-bottom: 10px;
}

.product-button-center {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    text-align: center;
}
